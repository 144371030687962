$grid-columns: 12;
$grid-column-width: 8.33333333333%;
$grid-padding: 12px;

.row {
	@include clearfix;
	max-width: 1200px;
	margin-left: auto;
	margin-right: auto;
	.row {
		margin-left: -1*$grid-padding;
		margin-right: -1*$grid-padding;
		max-width: none;
	}
}
.row1{
	width: 100%;
	clear: both;
}
@media #{$small} {
	.row {
		padding: 0 13px;
		.row {
			padding: 0;
		}
	}
}

.columns {
	padding-left: $grid-padding;
	padding-right: $grid-padding;
	min-height: 1px;
	position: relative;
	width: 100%;
	box-sizing:border-box;
}

@media #{$small} {
	[class*="column"] + [class*="column"]:last-child {
		float: right;
	}
}

@for $i from 1 through $grid-columns {
	.small-#{$i} {
		width: percentage(($i / $grid-columns));
		float: left;
	}
	.small-offset-#{$i} {
		margin-left: percentage(($i / $grid-columns));
	}
	.small-pull-#{$i} {
		left: auto;
		right: percentage(($i / $grid-columns));
	}
	.small-push-#{$i} {
		left: percentage(($i / $grid-columns));
		right: auto;
	}
}

@media #{$small} {
	@for $i from 1 through $grid-columns {
		.medium-#{$i} {
			width: percentage(($i / $grid-columns));
			float: left;
		}
		.medium-offset-#{$i} {
			margin-left: percentage(($i / $grid-columns));
		}
		.medium-pull-#{$i} {
			left: auto;
			right: percentage(($i / $grid-columns));
		}
		.medium-push-#{$i} {
			left: percentage(($i / $grid-columns));
			right: auto;
		}
	}
}

@media #{$medium} {
	@for $i from 1 through $grid-columns {
		.large-#{$i} {
			width: percentage(($i / $grid-columns));
			float: left;
		}
		.large-offset-#{$i} {
			margin-left: percentage(($i / $grid-columns));
		}
		.large-pull-#{$i} {
			left: auto;
			right: percentage(($i / $grid-columns));
		}
		.large-push-#{$i} {
			left: percentage(($i / $grid-columns));
			right: auto;
		}
	}
}
@media #{$large} {
	@for $i from 1 through $grid-columns {
		.xlarge-#{$i} {
			width: percentage(($i / $grid-columns));
			float: left;
		}
		.xlarge-offset-#{$i} {
			margin-left: percentage(($i / $grid-columns));
		}
		.xlarge-pull-#{$i} {
			left: auto;
			right: percentage(($i / $grid-columns));
		}
		.xlarge-push-#{$i} {
			left: percentage(($i / $grid-columns));
			right: auto;
		}
	}
}
@media #{$hd} {
	@for $i from 1 through $grid-columns {
		.hd-#{$i} {
			width: percentage(($i / $grid-columns));
			float: left;
		}
		.hd-offset-#{$i} {
			margin-left: percentage(($i / $grid-columns));
		}
		.hd-pull-#{$i} {
			left: auto;
			right: percentage(($i / $grid-columns));
		}
		.hd-push-#{$i} {
			left: percentage(($i / $grid-columns));
			right: auto;
		}
	}
}
.xlarge-0{
	@media #{$xlarge}{
		display: none;
	}
}