body.renault {
	background: #fdfeff;
	header {
		display: none;
	}
	* {
		font-family: $read;
	}
	h1 {
		font-family: $renault;
	}
	.renaultLogo {
		max-width: 100%;
		width: 100%;
		text-align: right;
		margin: 0 auto 24px;
		img{
			width: 320px;
			max-width: 100%;
		}
	}
	.renault_login {
		input, select {
			clear: both;
			width: 100%;
			margin: 0 auto 12px;
		}
		select {
			padding: 1px;
		}
		a {
			color: #000;
			display: inline-block;
			position: relative;
			&:after {
				content: '';
				width: 100%;
				height: 1px;
				background: #ffcc33;
				left: 0;
				position: absolute;
				top: 100%;
			}
		}
	}
	.filter-bar {
		input, select {
			margin-bottom: 6px;
			width: 220px;
			max-width: 100%;
			border-color: #eee;
			border-radius: 0;
			border-width: 2px;
			&:active, &:focus{
				border-color: $yellow;
				background: rgba($yellow, .2);
			}
		}
		.lkGroups {
			margin-top: 6px;
		}
	}
	.copytext {
		padding: 24px;
		background: #ccc;
		font-size: 12px;
		margin-top: 24px;
		margin-bottom: 24px;
		text-align: center;
		word-break: break-all;
	}
	table th {
		font-size: 13px;
		font-weight: normal;
		background: rgb(61,57,53);
	}
	table td, table td * {
		font-size: 14px;
	}
	table td span.show_small {
		@media#{$large}{
			display: none;
		}
		display: block;
	}
	table td.center, table th.center {
		text-align: center;
	}
	table tr {
		border-bottom: 1px solid rgb(207, 201, 196);
	}
	a {
		color: rgb(135, 135, 133);
		&:hover {
			color: darken(rgb(135, 135, 133), 10%);
		}
	}
	button.setChoose{
		white-space: nowrap;
		span.icon-plus-2{
			display: inline-block;
			margin-right: 6px;
		}
		&.disabled{
			background: rgb(135, 135, 133);
		}
	}
	.checkbox input[type=checkbox]:checked + label:before {
		border-color: $yellow;
	}
	.radio input[type=radio]:checked + label:before {
		border-color: $yellow;
	}
	.checkbox label:after, .radio label:after {
		background: $yellow;
	}
	.btn, .moduletable .button, input#button6, input.button, input[type=submit] {
		background: $yellow;
		color: #000;
		&:hover {
			background: lighten($yellow, 10%);
		}
	}
	.tip-wrap {
		background: rgb(135, 135, 133);
		padding: 3px;
		.tip-title, .tip-text {
			color: $light
		}
	}
	.row {
		max-width: 1450px;
	}
	.offer_single {
		.icon-plus-2 {
			margin-right: 6px;
			display: inline-block;
		}
		.linkDocsFormatted {
			margin-right: 12px;
		}
		button.btn.btn_show_antrag, .btn_show_callback {
			margin-top: 24px;
		}
	}
}
.moduletable.mod_emoportal_projects {
	background: $light;
	box-shadow: -6px 8px 24px -20px #333;
	min-height: 420px;
	padding: 6px;
	@media#{$medium}{
		padding: 12px;
	}
	div, p, span, li, strong {
		font-size: 14px;
	}
	.contentProject{
		box-shadow: none;
		padding: 0;
		padding-left: 12px;
	}
	> h3{
		top: -43px !important;
		white-space: nowrap;
		height: 44px !important;
		color: $yellow;
		&:before{
			content:'\e019';
			font-family: 'IcoMoon';
			display: inline-block;
			margin-right: 6px;
			font-size: 14px;
		}
		span.chooseCounter{
			font-size: 16px;
		}
	}
	.projectTitle {
		margin-bottom: 12px;
		float: left;
		width: 100%;
		display: block;
		border: 1px solid $border;
		padding: 4px 24px 4px 4px;
		position: relative;
		span.icon-edit {
			position: absolute;
			top: 4px;
			right: 4px;
		}
	}
	span.chooseCounter {
		font-weight: bold;
	}
	.item {

		border-bottom: 1px solid $border;
		margin-bottom: 6px;
		padding-bottom: 6px;
		width: 100%;
		float: left;
		clear: both;
		h4, h4 a {
			margin-bottom: 0;
			font-family: $base-font-family;
			text-transform: none;
			font-weight: bold;
			font-size: 14px;
			position: relative;
			padding-right: 12px;

			span{
				position: absolute;
				right: 0;
				top: 0;
				cursor: pointer;
				&:hover{
					color: $yellow;
				}
			}
		}
	}
}
$inactive: rgb(135, 135, 133);
.tabStructure{
	display: flex;
	border-bottom: 2px solid $inactive;
	flex-wrap: wrap;
	> h3{
		margin-bottom: 0;
		padding: 4px 8px;
		margin-right: 6px;
		border: 1px solid $border;
		cursor: pointer;
		border-bottom: 0;
		span.number{
			font-weight: bold;
			color: $red;
		}
		&.active{
			background: #ddd;
			font-weight: bold;
		}
	}
}