div.top_right {
	.moduletable {
		ul.nav {
			margin-top: 10px;
			margin-bottom: 10px;
			float: right;
			li {
				display: inline;
				list-style: none;
				margin-left: 12px;
				@media#{$xlarge}{
					margin-left: 24px;
				}
				&:first-child{
					margin-left: 0;
				}
				a {
					@include font-size(12);
				}
			}
		}
	}
}

nav.top_menu {
	.moduletable {
		float: left;
		overflow: visible;
		&.slidenav1{
			padding: 0;
		}
		ul.nav.menu {
			margin-left: 0;
			margin-bottom: 0;
			li {
				display: inline-block;
				list-style: none;
				position: relative;


				&.parent{
					> a, > span{
						&:after{
							content: '';
							font-family: monospace;
							position: absolute;
							transform: rotate(90deg);
							left: 50%;
							bottom: 4px;
							width: 0;
							height: 0;
							border-top: 6px solid transparent;
							border-bottom: 6px solid transparent;
							border-left: 6px solid $link;
							display: block;
						}
					}
				}
				> a, > span {
					color: $base;
					padding: 17px 15px;
					transition: .6s all ease 0s;
					text-decoration: none;
					display: block;
					@include font-size(16);
					font-weight: bold;
					text-transform: uppercase;
					@media#{$xlarge}{
						padding: 17px 24px;
					}
				}
				&:hover > a{
					color: $link;
				}
				ul.nav-child{
					position: absolute;
					top: 30px;
					left: 0;
					transition: .5s all ease 0s;
					margin-left: 0;
					width: auto;
					overflow: hidden;
					visibility: hidden;
					opacity: 0;
					background: rgba($link,.7);
					z-index: 99;
					min-width: 152px;
					box-shadow: 7px 0px 20px -10px #000;
					li{
						display: block;
						float: none;
						margin-right: 0;
						&:first-child a{
							border-left: 0;
						}
						a{
							@include font-size(14);
							padding: 8px 10px;
							background: $link;
							text-transform: none;
							letter-spacing: 0;
							font-family: $base-font-family;
							font-weight: normal;
							color: $light;
							border-right: 0;
							&:hover{
								background: $main;
								color: $light;
							}
							&:after{
								content:'';
							}
							&:first-child{
								border-left: 0;
							}
						}
						&.active{
							a{
								background: $main;
								color: $light;
							}
						}
					}
				}
				&:hover{
					ul.nav-child{
						opacity: 1;
						display: block;
						visibility: visible;
						top: 100%;
					}
				}
				&.active{
					a {
						background: $link_hover;
						color: $light;
					}
					ul {
						li{
							a{
							}
						}
					}
				}
			}
		}
	}
}

nav.bottom {
	.moduletable {
		ul.nav {
			margin-left: 0;
			li {
				display: inline;
				list-style: none;
				margin-right: 12px;
				@media#{$xlarge}{
					margin-right: 24px;
				}
				&:last-child{
					margin-right: 0;
				}
				a {
					@include font-size(14);
					color: $light;
				}
			}
		}
	}
}
.slidenav1, .slidenav2, .slidenav3, .slidenav4{
	display: none;
	&.show_pc{
		@media#{$large}{
		display: block;
		}
	}
}
/* Icon 4 */
.navWrap{
	background: $link;
	display: block;
	float: right;
	padding-left: 9px;
	padding-right: 9px;
	margin-top: 18px;
	border-radius: 100%;
	width: 56px;
	height: 56px;
	overflow: hidden;
	cursor: pointer;
	padding-top: 2px;
	&:hover{
		background: $main;
	}
	.innerNav{
		width: 31px;
		height: 31px;
		position: relative;
		margin: 12px auto;
		transform: rotate(0deg);
		transition: .5s ease-in-out;
		cursor: pointer;
		display: block;
		span, &:before, &:after{
			display: block;
			position: absolute;
			height: 4px;
			width: 100%;
			background: $light;
			opacity: 1;
			left: 0;
			transform: rotate(0deg);
			transition: .25s ease-in-out;
		}
		&:before {
			top: 0px;
			content: '';
			transform-origin: left center;
		}
		&:after {
			content:'';
			top: 12px;
			transform-origin: left center;
		}
		span {
			top: 24px;
			transform-origin: left center;
		}

	}
	&.open{
		.innerNav:before {
			transform: rotate(45deg);
			top: 1px;
			left: 4px;
		}
		.innerNav:after {
			width: 0;
			opacity: 0;
		}
		span {
			transform: rotate(-45deg);
			top: 23px;
			left: 4px;
		}
		span, &:before, &:after{
			background: rgba($light,5);
		}
	}
}