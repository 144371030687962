.blog, .blog-featured{
	h1{
		margin-top: 32px;
	}
	.item{
		@include bgblock();
		padding: 0;
		@media#{$large}{
			padding: 0;
		}
	}
	.items-leading{
		@include bgblock();
		padding: 0;
		@media#{$large}{
			padding: 0;
		}
	}

}
.category-list{
	@include bgblock();
}