* {
	box-sizing: border-box;
}

a {
	text-decoration: none;
	color: $link;
	transition: .5s all ease-in-out 0s;
	&:hover {
		color: $link_hover;
	}
}

h1, h1 * {
	@include font-size(24);
	@media#{$large}{
		@include font-size(28);
	}
	margin-bottom: 24px;
	margin-top: 0;
	color: $main;
	text-transform: uppercase;
	font-family: $fancy;
	font-weight: normal;
}

h2, h2 * {
	@include font-size(20);
	@media#{$large}{
		@include font-size(22);
	}
	margin-bottom: 24px;
	margin-top: 0;
	font-family: $fancy;
	font-weight: normal;
	color: $main;
	text-transform: uppercase;
}

h3, h3 * {
	@include font-size(18);
	margin-top: 0;
	font-weight: bold;
	color: $main;
}

h4, h4 * {
	font-weight: bold;
	margin-bottom: 24px;
	margin-top: 0;
	color: $main;
}

p {
	color: $base;
	line-height: 24px;
	margin-bottom: 24px;
}

ul, ol {
	margin-left: 24px;
	li {
		line-height: 24px;
	}
}

.item-page {
	ul, ol {
		margin-bottom: 24px;
	}
	ul{
		list-style: none;
		margin-left: 0;
		li{
			position: relative;
			padding-left: 24px;
			margin-bottom: 6px;
			&:before{
				content:'';
				background-image: url("../images/stichpunkt_m3e.svg");
				width: 16px;
				height: 16px;
				background-size: contain;
				position: absolute;
				left: 0;
				top: 3px;
				background-repeat: no-repeat;
			}
		}
	}
}

img {
	max-width: 100%;
	height: auto;
}

hr {
	height: 1px;
	width: 100%;
	background-color: $dark;
	clear: both;
	margin-bottom: 24px;
}

.item-image {
	display: block;
	margin: 0 auto 6px;
	text-align: center;
	.img_caption {
		@include font-size(14);
		max-width: 100%;
	}
	&.pull-right {
		max-width: 100%;
		@media#{$medium}{
			float: right;
			max-width: 50%;
			margin-left: 12px;
			margin-bottom: 6px;
		}
		.img_caption {
			text-align: right;
			padding-top: 12px;
		}
	}
	&.pull-left {
		max-width: 100%;
		@media#{$medium}{
			float: left;
			max-width: 50%;
			margin-right: 12px;
			margin-bottom: 6px;
		}
		.img_caption {
			text-align: left;
			padding-top: 12px;
		}
	}
}

/*Artikelinfos*/
dl.article-info {
	@include font-size(12);
	margin-bottom: 12px;
	span {
		@include font-size(12);
	}
	time {
		@include font-size(12);
	}
	dd.createdby {
		@include font-size(12);
		span {
			@include font-size(12);
		}
	}
}

/*Pagination*/

.pagination {
	clear: both;
	float: left;
	width: 100%;
	margin-bottom: 24px;
	margin-top: 24px;
	p.counter {
		display: none;
		margin-bottom: 0;
		@media #{$large}{
			float: right;
			display: block;
		}
	}
	ul {
		margin-left: 0;
		width: 100%;
		li {
			list-style: none;
			display: inline;
			margin-right: 10px;
			a {
				padding: 5px 10px;
				color: $link;
				background: $light;
				border: 1px solid $dark;
				&:hover {
					color: $link-hover;
				}
			}
			span {
				padding: 5px 10px;
			}
			&.pagination-end, &.pagination-start {
				display: none;
			}
			&.pagination-next {
				@media #{$large-max}{
					float: right;
					margin-right: 0;
				}
			}
			@media #{$large-max}{
				display: none;
				&.pagination-prev, &.pagination-next {
					display: inline-block;
				}
			}
		}
	}
}

ul.pagenav {
	width: 100%;
	float: left;
	clear: both;
	margin-left: 0;
	list-style: none;
	li {
		display: inline-block;
		a {
			background: $main;
			color: $light;
			padding: 5px;
			@include font-size(12);
			display: block;
			margin-bottom: 12px;
			&:hover {
				background: darken($main, 10%);
			}
		}
	}
	li.pagenav-prev {
		float: left;
		a:before {
			content: '<';
			padding-right: 5px;
		}
	}
	li.pagenav-next {
		float: left;
		clear: both;
		@media#{$medium}{
			float: right;
			clear: none;
		}
		a:after {
			content: '>';
			padding-left: 5px;
		}
	}
}

input#button6, input.button, .moduletable .button, .btn, input[type='submit'] {
	@include button();
	margin-bottom: 12px;
}

/* Fieldset reset */
fieldset {
	border: 0;
	margin: 0;
	margin-bottom: 24px;
	padding: 0;
	.btn-group {
		float: left;
		label.element-invisible {
			display: none;
		}
		#filter-search {
			min-width: 210px;
			padding: 4px;
			background: $dark;
			color: $light;
			border-radius: 3px;
			@include font-size(14);
		}
		&.pull-right {
			float: right;
			select {
				border: 1px solid $border;
			}
		}
	}
}
.loading_screen {
	width: 100%;
	position: relative;
	text-align: center;
	transform: scale(.4);
	.lds-roller {
		display: inline-block;
		position: relative;
		width: 32px;
		height: 32px;
	}
	.lds-roller div {
		animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
		transform-origin: 32px 32px;
	}
	.lds-roller div:after {
		content: " ";
		display: block;
		position: absolute;
		width: 6px;
		height: 6px;
		border-radius: 50%;
		background: $light;
		box-shadow: 0 0 4px black;
		margin: -3px 0 0 -3px;
	}
	.lds-roller div:nth-child(1) {
		animation-delay: -0.036s;
	}
	.lds-roller div:nth-child(1):after {
		top: 50px;
		left: 50px;
	}
	.lds-roller div:nth-child(2) {
		animation-delay: -0.072s;
	}
	.lds-roller div:nth-child(2):after {
		top: 54px;
		left: 45px;
	}
	.lds-roller div:nth-child(3) {
		animation-delay: -0.108s;
	}
	.lds-roller div:nth-child(3):after {
		top: 57px;
		left: 39px;
	}
	.lds-roller div:nth-child(4) {
		animation-delay: -0.144s;
	}
	.lds-roller div:nth-child(4):after {
		top: 58px;
		left: 32px;
	}
	.lds-roller div:nth-child(5) {
		animation-delay: -0.18s;
	}
	.lds-roller div:nth-child(5):after {
		top: 57px;
		left: 25px;
	}
	.lds-roller div:nth-child(6) {
		animation-delay: -0.216s;
	}
	.lds-roller div:nth-child(6):after {
		top: 54px;
		left: 19px;
	}
	.lds-roller div:nth-child(7) {
		animation-delay: -0.252s;
	}
	.lds-roller div:nth-child(7):after {
		top: 50px;
		left: 14px;
	}
	.lds-roller div:nth-child(8) {
		animation-delay: -0.288s;
	}
	.lds-roller div:nth-child(8):after {
		top: 45px;
		left: 10px;
	}
	@keyframes lds-roller {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}

}
.tooltip.fade {
	display: none !important;
}
p.hint{
	@include font-size(14);
	color: lighten($dark, 20%);
}
/* Table */

table {
	max-width: 100%;
	width: 100%;

	color: $dark;
	margin-bottom: 24px;
	th {
		background-color: $dark;
		padding: 3px 5px;
		color: $light;
		text-align: left;
		@include font-size(12);
		transition: .5s all ease 0s;
		* {
			color: $light;
			margin-bottom: 0;
		}
		@media #{$medium}{
			@include font-size(16);
		}
	}
	tbody {

		tr {
			transition: .5s background-color ease 0s;
			td {
				padding: 3px 5px;
				vertical-align: top;

			}
		}
	}
	&.responsive {
		width: 100%;
		color: $dark;
		margin-bottom: 24px;
		table-layout: fixed;
		th {
			background-color: $dark;
			padding: 5px 10px;
			color: $light;
			text-align: left;
			@include font-size(12);
			transition: .5s all ease 0s;
			* {
				color: $light;
				margin-bottom: 0;
			}
			@media #{$medium}{
				@include font-size(16);
			}
		}
		tbody {

			tr {
				transition: .5s background-color ease 0s;
				td {
					padding: 5px 5px;
					vertical-align: top;

					@media#{$medium-max}{
						width: 50% !important;
						display: block;
						float: left;
						word-break: break-word;
					}
					@media#{$small-max}{
						width: 100% !important;
					}
					img {
						margin: 0 auto 24px;
						display: block;
						float: none !important;
					}
					@media#{$small}{
					}
					@media #{$medium}{
						padding: 5px 10px;
					}
					@media#{$large}{
						float: none;
						display: table-cell;
						box-sizing: border-box;
						&:first-child {
							padding-left: 0;
						}
						&:last-child {
							padding-right: 0;
						}
					}

					a {
						font-weight: bold;
					}

				}

			}
		}
	}
	&.zebra {
		border: 1px solid $border;
		tr {
			&:nth-child(odd) {
				background-color: $bg;
				&:hover {
					background-color: darken($bg, 10%);
				}
			}
			&:nth-child(even) {
				background-color: $light;
				&:hover {
					background-color: darken($bg, 10%);
				}
			}
			td {
				@media#{$medium}{
					border-right: 1px solid $border;
				}
				&:last-child {
					border-right: 0;
				}
				&:first-child {
					padding-left: 5px;
				}
			}
		}
	}
	&.info {
		width: auto;
		@media#{$small-max}{
			width: 100% !important;
		}
		th, th * {
			@include font-size(13);
		}
		tr {
			td {
				@include font-size(13);
				* {
					@include font-size(13);
				}
				&:first-child, &:last-child {
					padding: 5px 5px;
					@media #{$medium}{
						padding: 5px 10px;
					}
				}
			}
		}
	}
	&.sticky {
		min-width: 724px;
		tr {
			td {
				@media#{$medium-max}{
					width: auto !important;
					display: table-cell;
					float: none;
					border-right: 1px solid $border;
					word-break: normal;
				}
				@media#{$small-max}{
					width: auto !important;
				}
			}
		}
	}
	&.stacked {
		width: 100% !important;
		tr {

			border-bottom: 1px solid $link_hover;
			margin-bottom: 1px;
			padding-bottom: 1px;
			&:first-child {
				td {
					font-weight: bold;
					* {
						margin-bottom: 0;
					}
				}
			}
			> td {
				padding: 5px !important;
				text-align: center;
				* {
					margin-bottom: 0;
					text-align: center;
				}
			}
			td:first-child {
				font-weight: bold;
				padding: 5px 10px;
				background: darken($bg, 10%);
			}
			td:nth-child(2) {
				background: lighten(#ddd, 10%)
			}
			td:nth-child(3) {
				background: lighten(#ddd, 5%)
			}
			td:nth-child(4) {
				background: #ddd;
			}
		}
	}
	&.category {

	}
	&.dfContactTable {
		tr th {
			width: 54px;
			padding: 5px 10px;
			text-align: center;
		}
	}
}

.sketch_grid {
	margin-bottom: 24px;
	&.tleft {
		float: left;
	}
}

// Inputs
input {
	&[type='text'], &[type='password'], &[type='email'] {
		border: 1px solid $border;
		padding: 2px;
	}
}

textarea {
	border: 1px solid $border;
	padding: 2px;
}

select {
	border: 1px solid $border;
}

.sketch_calendar {
	.btn {
		padding: 1px 11px;
		border-radius: 0 5px 5px 0;
		span.icon-calendar {
			@include icon($icon-calendar);
			&:before {
				padding-right: 0;
			}
		}
	}
}

// In Item-page
// Article Summary
.article-index {
	background: $bg;
	padding: 12px;
	margin-bottom: 24px;
	&.pull-right {
		float: right;
	}
	&.pull-left {
		float: left;
	}
}

.pagenavcounter {
	float: right;
	background: $dark;
	color: $light;
	border-radius: 8px;
	padding: 4px 8px;
	clear: right;
	margin-bottom: 24px;
}

.pager {
	ul {
		width: 100%;
		float: left;
		clear: both;
		margin-top: 24px;
		li {
			padding-left: 0;
			&:first-child {
				float: left;
			}
			&:last-child {
				float: right;
			}
		}
	}
}

.alert {
	background-color: rgba($light, .92);
	padding: 12px;
	overflow: hidden;
	float: left;
	width: 100%;
	margin-bottom: 24px;
	border: 2px solid $border;
	.close {
		border: 1px solid $border;
		border-radius: 100%;
		width: 19px;
		line-height: 18px;
		height: 19px;
		display: block;
		text-align: center;
		color: $border;
		float: right;
		cursor: pointer;
	}
	p {
		margin-bottom: 6px;
	}
	&.alert-message, &.alert-info {
		border: 2px solid $main;
		.close {
			border: 1px solid $main;
			color: $main;
		}
	}
	&.alert-warning, &.alert-error {
		border: 2px solid $red;
		.close {
			border: 1px solid $red;
			color: $red;
		}
	}
	&.alert-success, &.alert-notice {
		border: 2px solid $green;
		.close {
			border: 1px solid $green;
			color: $green;
		}
	}
}

// Base Styling Inputs

input[type="text"], input[type="email"], input[type="password"], input[type="number"], input[type="phone"], select, textarea {
	border: 1px solid $main;
	border-radius: 3px;
}

// Icons for Base Elements
a[href^="mailto:"] {
	@include icon($icon-envelop);
}

a[href^="tel:"] {
	@include icon($icon-phone);
}

span.fax {
	@include icon($icon-printer);
}

*[data-title] {
	cursor: pointer;
	position: relative;
	&:hover {
		&:after {
			content: attr(data-title);
			padding: 4px 8px;
			color: #333;
			position: absolute;
			left: 12px;
			top: 82%;
			@include font-size(14);
			max-width: 500px;
			text-transform: initial;
			z-index: 20;
			box-shadow: 0 0 4px #222;
			background: $bg;
		}
	}
}

// Frontend Joomla-native Popups
.popover {
	top: 0;
	left: 0;
	z-index: 1010;
	display: none;
	max-width: 276px;
	padding: 1px;
	text-align: left;
	background-color: $light;
	border: 1px solid $border;
	border-radius: 6px;
	box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
	white-space: normal;
	position: absolute;
	&.fade {
		opacity: 0;
		-webkit-transition: opacity .15s linear;
		transition: opacity .15s linear;
		&.in {
			opacity: 1;
		}
	}
	&.top {
		margin-top: -10px;
		> .arrow {
			left: 50%;
			margin-left: -11px;
			border-bottom-width: 0;
			border-top-color: $border;
			bottom: -11px;
			&:after {
				bottom: 1px;
				margin-left: -10px;
				border-bottom-width: 0;
				border-top-color: $light;
			}
		}
	}
	&.right {
		margin-left: 10px;
		> .arrow {
			top: 50%;
			left: -11px;
			margin-top: -11px;
			border-left-width: 0;
			border-right-color: $border;
			&:after {
				left: 1px;
				bottom: -10px;
				border-left-width: 0;
				border-right-color: $light;
			}
		}
	}
	&.bottom {
		margin-top: 10px;
		> .arrow {
			left: 50%;
			margin-left: -11px;
			border-top-width: 0;
			border-bottom-color: $border;
			top: -11px;
			&:after {
				top: 1px;
				margin-left: -10px;
				border-top-width: 0;
				border-bottom-color: $light;
			}
		}
	}
	&.left {
		margin-left: -10px;
		> .arrow {
			top: 50%;
			right: -11px;
			margin-top: -11px;
			border-right-width: 0;
			border-left-color: $border;
			&:after {
				right: 1px;
				border-right-width: 0;
				border-left-color: $light;
				bottom: -10px;
			}
		}
	}
	> .arrow {
		border-width: 11px;
		&:after {
			border-width: 10px;
			content: "";
		}
	}
	> .arrow, > .arrow:after {
		position: absolute;
		display: block;
		width: 0;
		height: 0;
		border-color: transparent;
		border-style: solid;
	}
	.popover-title {
		margin: 0;
		padding: 8px 14px;
		@include font-size(14);
		font-weight: 400;
		line-height: 18px;
		background-color: $main;
		color: $light;
		border-bottom: 1px solid $border;
		border-radius: 5px 5px 0 0;
	}
	.popover-content {
		padding: 9px 14px;
	}
}

.popover.bottom > .arrow:after, .popover.left > .arrow:after, .popover.right > .arrow:after, .popover.top > .arrow:after {
	content: " ";
}

span.copyright {
	display: block;
	text-align: right;
	@include font-size(14);
}

.content_rating, form.rate_content {
	p.unseen.element-invisible {
		display: none;
	}
}
video{
	display: block;
}
p.intro{
	font-weight: bold;
	@include font-size(18, 1.5);
	color: $main;
	text-transform: uppercase;
}
.content_rating {
	float: right;
	span.stars {
		color: gold;
		font-size: 27px;
		line-height: 24px;
		cursor: pointer;
	}
	span.rating_count {
		position: relative;
		top: -3px;
	}
}

// Material Radios/Checkbox
.radio {
	margin: 16px 0;
	input[type="radio"] {
		opacity: 0;
		position: absolute;
		&:checked + label:before {
			border-color: $md-radio-checked-color;
			animation: ripple 0.2s linear forwards;
		}
		&:checked + label:after {
			transform: scale(1);
		}
	}
	label {
		display: inline-block;
		position: relative;
		padding: 0 ($md-radio-size + 10px);
		margin-bottom: 0;
		cursor: pointer;
		vertical-align: bottom;
		&:before, &:after {
			position: absolute;
			content: '';
			border-radius: 50%;
			transition: all .3s ease;
			transition-property: transform, border-color;
		}
		&:before {
			left: 0;
			top: 0;
			width: $md-radio-size;
			height: $md-radio-size;
			border: 2px solid $md-radio-border-color;
		}
		&:after {
			top: 5px;
			left: 5px;
			width: $md-radio-checked-size;
			height: $md-radio-checked-size;
			transform: scale(0);
			background: $md-radio-checked-color;
		}
	}
}

.checkbox {
	margin: 16px 0;
	input[type="checkbox"] {
		opacity: 0;
		position: absolute;
		&:checked + label:before {
			border-color: $md-radio-checked-color;
			animation: ripple 0.2s linear forwards;
		}
		&:checked + label:after {
			transform: scale(1);
		}
	}

	label {
		display: inline-block;
		position: relative;
		padding: 0 ($md-radio-size + 10px);
		margin-bottom: 0;
		cursor: pointer;
		vertical-align: bottom;
		&:before, &:after {
			position: absolute;
			content: '';
			transition: all .3s ease;
			transition-property: transform, border-color;
		}
		&:before {
			left: 0;
			top: 0;
			width: $md-radio-size;
			height: $md-radio-size;
			border: 2px solid $md-radio-border-color;
		}
		&:after {
			top: 5px;
			left: 5px;
			width: $md-radio-checked-size;
			height: $md-radio-checked-size;
			transform: scale(0);
			background: $md-radio-checked-color;
		}
	}
}
.bg_dark{
	@include bgfull($link);
	margin-bottom: 24px;
	*{
		color: $light;
	}
	p.intro{
		color: $light;
	}
}

blockquote{
	background: $link;
	padding: 12px;
	display: block;
	width: 100%;
	margin-bottom: 24px;
	@media#{$large}{
		padding: 24px;
	}
	*{
		color: $light;
	}
	p{
		&:last-child{
			margin-bottom: 0;
			font-size: 14px;
			font-weight: bold;
		}
	}
}