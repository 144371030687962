* {
	font-size: $base-font-size;
	font-family: $base-font-family;
}

body {
	background-color: $light;
}

.allwrap {
	overflow: hidden;
	margin-top: 0px;
}

header {
	.sketchwrap_top {
		width: 100%;
		height: 90px;
		position: fixed;
		top: 0;
		z-index: 10;
		padding-bottom: 15px;
		background-color: rgba($light, .5);
		&.solid{
			background-color: rgba($light,.9);
		}

		.logo {
			img{
				width: 127px;
				margin: 6px auto 0;
				display: block;
			}
			p {
				font-weight: 400;
			}
		}
	}
	.sketchwrap_slider {
		overflow: hidden;
		max-height: 100vh;
		float: left;
		width: 100%;
		clear: both;
	}
	.top_left{
		margin-top: 12px;
	}
	.header_login svg{
		width: 28px;
		margin-top: 8px;
		fill: $link;
		&:hover{
			fill: $link_hover;
			.cls-2{
				stroke: $link_hover;
			}
		}
		.cls-2{
			stroke: $link;
		}
		path.cls-1{
			fill: $light;
		}
	}
}

main {
	background: $light;
	padding-top: 24px;
	padding-bottom: 24px;
}

body.start {
	.allwrap{
		margin-top: 0;
	}
	main {
		background: transparent;
	}
}

.item-page{
	padding-top: 24px;
}

footer {
	width: 100%;
	float: left;
	clear: both;
	background: $light;
	padding-top: 24px;
	padding-bottom: 24px;
	.moduletable  h3 {
		text-transform: uppercase;
		margin-bottom: 12px;
		font-family: $fancy;
	}
	ul.nav {
		list-style: none;
		margin-left: 0;
		li {
			margin-bottom: 6px;
			display: block;
		}
	}
}

.match {
	transition: none !important;
}

body .cc_banner-wrapper:hover {
	a.cc_more_info {
		color: $main !important;
	}
	a.cc_btn.cc_btn_accept_all {
		background: $main !important;
		color: $light !important;
	}
}